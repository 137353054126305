<template>
  <div>
   <!-- Components -->
    <darkBackground />
    <infoOverlay text="Möchtest du das Training wirklich beenden?" button="Training beenden" @buttonClicked="confirmCancelTraining" index=1 />
    <pfadaufgabenOverview :pfadaufgaben=pfadaufgaben :reviewMode="review" :currentPfadaufgabeId=currentPfadaufgabeId @changePfadaufgabe="changePfadaufgabe" @buttonClicked="finishTraining" />
    <listPicker :currentQuestionId=currentPfadaufgabeId listName="pfadaufgaben"/>
    <results resultText="Aufgaben gelöst" :max="pfadaufgaben?.length" saveType="Pfadaufgabe" />
    <!-- Components -->

    <!-- Nav Bar Overlay-->
    <div class="w-full md:w-24 md:h-screen bg-new-yellow md:bg-transparent fixed flex flex-row md:flex-col justify-between md:justify-between items-center z-10 top-0 md:left-0 p-5 font-visby text-new-black border-b-2 md:border-none  border-new-yellow" :class="{'pt-safe' : parseInt(sat) !== 0, 'transition duration-300 ease-in-out border-new-yellow2' : scrollDown}">
      <div class="relative flex flex-row justify-center items-center w-full md:order-last">
        <div class="z-10 cursor-pointer" @click="cancelTraining" >
          <backSVG color='red' class="md:hidden flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
          <backBoldSVG color='red' class="hidden md:block flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
        </div>
        <div class="md:hidden transition duration-300 ease-in-out w-full text-center text-lg opacity-0" :class="{'transition duration-300 ease-in-out opacity-100' : scrollDown}">Aufgabe {{currentPfadaufgabe + 1}} von {{pfadaufgaben?.length}}</div>
      </div>
      <starSVG :full="includedInList ? true : false" class=" flex-shrink-0 mr-5 md:mr-0 md:p-2 md:w-12 md:h-12" @click="openListPicker" />
      <gridSVG @click="openOverview" class="flex-shrink-0 md:order-first md:p-2 md:right-0 md:w-12 md:h-12 md:mt-5 cursor-pointer"/>
    </div>
    <!-- Nav Bar Overlay-->

    <!-- Content Wrapper -->
    <div class="w-full min-h-screen md:max-h-screen md:overflow-hidden p-5 pb-28 pt-10 md:p-0 md:mt-0 md:pl-24 bg-new-yellow font-visby text-new-black select-none md:mb-0 md:flex md:flex-row" :class="{'pt-safe' : parseInt(sat) !== 0}">

      <!-- Desktop Left Side -->
      <div class="md:w-1/2 md:px-10 py-5 md:flex md:flex-col md:h-screen">

        <!-- Scrollable -->
        <div class="md:flex-grow md:overflow-y-auto scrollbar mt-6">

          <!-- Heading -->
          <div class="mt-6 font-visby font-bold text-3xl">Pfadaufgaben</div>
          <div class="text-xl">Aufgabe {{ currentPfadaufgabe + 1 }} von {{ pfadaufgaben?.length }}</div>
          <div class="font-bold mt-1" v-if="pfadaufgaben[currentPfadaufgabe].attributes.Aufgabenbeschreibung">{{ pfadaufgaben[currentPfadaufgabe].attributes.Aufgabenbeschreibung }}</div>
          <div class="font-bold mt-1" v-if="taskType === 'ComponentAufgabenMcFrage'">Wähle {{maxAnswers}} {{maxAnswers !== 1 ? 'Antworten' : 'Antwort'}}</div>

          <!-- Pfadaufgabe Lückentext etc -->
          <div v-if="taskType === 'ComponentAufgabenLueckentext'">
            <tipTap :content="pfadaufgaben[currentPfadaufgabe]?.attributes?.Aufgabentyp[0]?.content" :selectedAnswers=selectedAnswers :extraLineHeight="true" type="lückentext" />
          </div>

          <!-- Vokabeltrainer -->
          <div v-if="taskType === 'ComponentAufgabenVokabel'">
            <tipTap :content="pfadaufgaben[currentPfadaufgabe]?.attributes?.Aufgabentyp[0]?.content" />
          </div>

          <!-- Begriffspaare -->
          <div v-if="taskType === 'ComponentAufgabenBegriffspaare' && possibleAnswers && result !== 'success'" class="flex flex-wrap w-auto" ref="pairAnswers">
            <div v-for="answer in splitPairAnswers(possibleAnswers)" :key=answer id="pairAnswer" :data-answer="answer" class="bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-3 mr-3"
            :class="{'bg-new-black text-white' : answer === focusedAnswer }"
            @touchstart.passive="pairDragStart"
            @touchend.passive="pairDragEnd"
            @touchmove.prevent="pairDrag"
            @mousedown.passive="pairDragStart"
            @mouseup.passive="pairDragEnd"
            @mousemove.prevent="pairDrag">
              {{answer}}
            </div>
          </div>
          <div v-else-if="taskType === 'ComponentAufgabenBegriffspaare' && possibleAnswers" class="mt-10 text-center p-5">Du hast alle Begriffe richtig zugeordnet.</div>

          <!-- Listen -->
          <div v-if="taskType === 'ComponentAufgabenListenzuordnung'">
            <div v-if="pfadaufgaben[currentPfadaufgabe]?.attributes?.Aufgabentyp[0].Liste" class="sticky top-20 z-10 flex flex-row justify-between items-center mt-5 bg-white rounded-xl shadow-new p-3 px-5" id="liste">
              <div class="break-words hyphen w-5/12">{{ pfadaufgaben[currentPfadaufgabe]?.attributes?.Aufgabentyp[0].Liste[0].Listenname }}</div>
              <dragSVG class="flex-shrink-0" />
              <div class="break-words hyphen w-5/12 text-right">{{ pfadaufgaben[currentPfadaufgabe]?.attributes?.Aufgabentyp[0].Liste[1].Listenname }}</div>
            </div>

            <div v-if="possibleAnswers" class="flex flex-col justify-center items-center mt-5" ref="answers">
              <div v-for="answer in possibleAnswers" :key="answer" id="answer" :data-answer="answer" data-moved="0" data-maxleft="0" data-maxright="0"
                class="bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-2 mb-2 w-2/3 text-center"
                :class="{'ring-2 ring-new-orange' : answer === focusedAnswer}"
                @click="focusedAnswer = answer"
                @touchstart.passive="answerDragStart"
                @touchend.passive="answerDragEnd"
                @touchmove="answerDrag"
                @mousedown.passive="answerDragStart"
                @mouseup.passive="answerDragEnd"
                @mousemove="answerDrag">
                <chevronSVG class="transform rotate-180 cursor-pointer" @click="moveLeft($event)"/>
                <div>{{ answer }}</div>
                <chevronSVG class="cursor-pointer" @click="moveRight($event)"/>
              </div>
            </div>
          </div>

          <!-- Scrollspace -->
          <div v-if="possibleAnswers" class="flex flex-row flex-wrap p-3 opacity-0 max-h-52 max-w-full">
            <div v-for="answer in possibleAnswers" :key=answer >
              <div>{{answer}}</div>
            </div>
          </div>

        </div>
      </div>

      <!-- Desktop Right Side -->
      <div class="hidden md:w-1/2 md:px-10 py-5 md:flex md:flex-col md:h-screen bg-new-yellow3"></div>

    </div>
    <!-- Content Wrapper -->

    <!-- Pfadaufgaben Nav -->
    <div class="fixed bottom-0 md:right-0 md:top-12 w-full md:w-1/2  pointer-events-none font-visby text-new-black">

      <!-- Check Answer -->
      <div v-if="review ||
      (taskType === 'ComponentAufgabenMcFrage' && maxAnswers === selectedAnswers?.length) ||
      (taskType === 'ComponentAufgabenVokabel' && inputText !== '') ||
      (taskType === 'ComponentAufgabenBegriffspaare' && selectedAnswers?.length === 2) ||
      (taskType === 'ComponentAufgabenListenzuordnung' && maxAnswers === selectedAnswers?.length) ||
      (taskType === 'ComponentAufgabenLueckentext' && !selectedAnswers.includes('OPTION'))"
      class="md:absolute md:bottom-0 md:pb-5 md:w-full md:px-12 md:pl-24">
        <div v-if="!result && currentTraining.progress[progressIndex].result !== 'success' && !review" class="w-full h-auto bg-new-black text-white text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="checkAnswer()">Antwort prüfen</div>
        <div v-else-if="result === 'continue'" class="w-full h-auto text-new-black bg-green-200 text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="hidePairs()">Super, das war richtig!</div>
        <div v-else-if="result === 'success' || currentTraining.progress[progressIndex].result === 'success'" class="w-full h-auto text-new-black bg-green-200 text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="nextPfadaufgabe()">Super! Weiter</div>
        <div v-else-if="result === 'fail' || (review && currentTraining.progress[progressIndex].result === 'fail' || !currentTraining.progress[progressIndex].result)" class="w-full h-auto text-new-black bg-red-200 text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="refresh()">Das war leider falsch.</div>
      </div>

      <!-- NAV-->
      <div class="w-full bg-white md:bg-transparent shadow-top md:shadow-none pointer-events-auto md:px-12 md:pl-24">
      <div class="flex flex-row justify-between md:justify-start items-center p-3 border-new-black border-b md:border-none">
        <div class="flex flex-row justify-between flex-grow items-center md:bg-white md:p-3 md:rounded-xl md:mr-3 cursor-pointer select-none">
          <div v-if="currentPfadaufgabe > 0" class="mx-2 md:mx-0 bg-new-black text-white px-3 py-1 rounded-lg" @click="previousPfadaufgabe()">Zurück</div>
          <div v-if="currentPfadaufgabe < pfadaufgaben?.length -1" class="mx-2 md:mx-0 bg-new-black text-white px-3 py-1 rounded-lg" @click="skipPfadaufgabe()">{{ currentTraining.progress[progressIndex].result === 'success' || review ? 'Weiter' : 'Überspringen' }}</div>
          <div v-if="currentPfadaufgabe === pfadaufgaben?.length - 1" class="mx-2 md:mx-0 bg-new-black text-white px-3 py-1 rounded-lg" @click="finishTraining()">Beenden</div>
        </div>
        <div v-if="!review" class="flex flex-row items-center">
          <div class="md:p-3 md:rounded-xl md:mr-3 mr-2 cursor-pointer select-none bg-new-black px-3 py-1 rounded-lg" :class="{'opacity-50' : currentTraining.progress[progressIndex].result === 'success'}" @click="currentTraining.progress[progressIndex].result !== 'success' ? refresh() : ''">
            <reloadSVG class="text-white" />
          </div>
          <div v-if="taskType !== 'ComponentAufgabenListenzuordnung' && taskType !== 'ComponentAufgabenBegriffspaare'" class="md:bg-white md:p-3 md:rounded-xl cursor-pointer select-none bg-new-black px-3 py-1 rounded-lg"
            :class="{'opacity-50' : currentTraining.progress[progressIndex].result === 'success'}" @click="currentTraining.progress[progressIndex].result !== 'success' ? removeLastAnswer() : ''">
            <removeSVG class="text-white" />
          </div>
        </div>
      </div>

        <!-- Space MC Fragen-->
        <div v-if="taskType === 'ComponentAufgabenMcFrage' || review" class="w-full h-24"></div>

        <!-- Info Drag -->
        <div v-else-if="taskType === 'ComponentAufgabenListenzuordnung'" class="w-full h-24 flex flex-row justify-center items-center text-center px-10">
          Verschiebe die Antwort unter die richtige Liste.
        </div>

        <!-- Begriffspaare-->
        <div v-else-if="taskType === 'ComponentAufgabenBegriffspaare'" class="w-full h-24 flex flex-row justify-center items-center text-center px-10">
          Verschiebe die passenden Begriffe übereinander.
        </div>

        <!-- Input field-->
        <div v-else-if="taskType === 'ComponentAufgabenVokabel'" class="p-5 pb-24">
          <input v-model="inputText" placeholder="Begriff eingeben" class="w-full appearance-none outline-none border border-new-black py-3 placeholder-new-midGrey text-center">
        </div>

        <div v-else-if="taskType === 'ComponentAufgabenLueckentext'">
          <div v-if="possibleAnswers" class="flex flex-row flex-wrap p-3 pb-8 md:w-10/12 max-h-52 overflow-y-auto">
            <div v-for="answer in possibleAnswers" :key=answer >
              <div class="text-white bg-new-black rounded-lg p-3 mr-2 mb-2 cursor-pointer select-none" :class="{'opacity-40' : selectedAnswers.includes(answer)}" @click="addAnswer(answer)">{{answer}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// SVG
import gridSVG from '../../../assets/svg/grid.vue';
import backSVG from '../../../assets/svg/back.vue';
import backBoldSVG from '../../../assets/svg/backBold.vue';
import starSVG from '../../../assets/svg/star.vue';
import reloadSVG from '../../../assets/svg/reload.vue';
import removeSVG from '../../../assets/svg/remove.vue';
import dragSVG from '../../../assets/svg/drag.vue';
import chevronSVG from '../../../assets/svg/chevron.vue';

// Mixins
import detectNotch from '../../../mixins/detectNotch.vue';
import scrollTo from '../../../mixins/scrollTo.vue';
import fadeInOut from '../../../mixins/animations/fadeInOut.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';
import mobile from '../../../mixins/mobile.vue';
import pfadaufgabenFunctions from '../../../mixins/pfadaufgabenFunctions.vue';
import points from '../../../mixins/points.vue';

// Components
import tipTap from '../../../symbols/tipTap.vue';
import darkBackground from '../../../symbols/darkBackground.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';
import listPicker from '../../../symbols/listPicker.vue';
import results from '../components/results.vue';
import pfadaufgabenOverview from '../components/pfadaufgabenOverview.vue';



export default {
  name: 'exercises',
  mixins: [detectNotch, fadeInOut, iosVibrations, scrollTo, mobile, pfadaufgabenFunctions, points],
  components: {
    chevronSVG, gridSVG, backSVG, backBoldSVG, starSVG, tipTap, reloadSVG, removeSVG, infoOverlay, darkBackground, pfadaufgabenOverview, listPicker, results, dragSVG,
  },

  data() {
    return {
      currentPfadaufgabe: 0,
    };
  },

  computed: {
    // STRAPI EXECUTER
    updateUserDataWrongPlayedExercises() {
      return this.$store.state?.updateUserDataWrongPlayedExercises;
    },
    updateUserDataRecentPlayedExercises() {
      return this.$store.state?.updateUserDataRecentPlayedExercises;
    },
    // DATA
    scrollDown() {
      return this.$store.state?.layout?.scrollDown;
    },
    currentTraining() {
      return this.$store.state.training?.currentTraining;
    },
    pfadaufgaben() {
      return this.currentTraining?.pfadaufgabenData;
    },
    currentPfadaufgabeId() {
      return this?.pfadaufgaben[this?.currentPfadaufgabe]?.id;
    },
    includedInList() {
      const myList = this.$store.state?.profile?.me?.userData?.data?.attributes?.myLists;
      let included = false;
      myList.forEach((list) => {
        if (list.pfadaufgaben.ids.includes(this?.currentPfadaufgabeId)) {
          included = true;
        }
      });
      return included;
    },
    userId() {
      return this.$store.state.auth?.userId;
    },
    progressIndex() {
      return this.currentTraining.progress.findIndex((pfadaufgabe) => pfadaufgabe.pfadaufgabeId === this.currentPfadaufgabeId);
    },
    taskType() {
      // eslint-disable-next-line no-underscore-dangle
      return this.pfadaufgaben[this.currentPfadaufgabe]?.attributes?.Aufgabentyp[0].__typename;
    },
    maxAnswers() {
      if (this.taskType === 'ComponentAufgabenLueckentext') {
        return this.pfadaufgaben[this.currentPfadaufgabe].attributes.Aufgabentyp[0].content.split('</s>').length - 1;
      }
      if (this.taskType === 'ComponentAufgabenBegriffspaare') {
        return 2;
      }
      if (this.taskType === 'ComponentAufgabenListenzuordnung') {
        let count = 0;
        this.pfadaufgaben[this.currentPfadaufgabe].attributes.Aufgabentyp[0].Liste.forEach((liste) => {
          const newList = liste.Answers.split(';');
          count += newList.length;
        });
        return count;
      }
      return null;
    },
    wrongPlayed() {
      return this.$store.state.profile.me?.userData?.data?.attributes?.wrongPlayedExercise;
    },
    recentPlayed() {
      return this.$store.state.profile.me?.userData?.data?.attributes?.recentPlayedExercise;
    },
    totalPlayed() {
      return this.$store.state.profile.me?.userData?.data?.attributes?.totalPlayedExercises;
    },
    possibleAnswers() {
      if (!this.leftAnswers.length > 0) {
        const pfadaufgabe = this.pfadaufgaben[this.currentPfadaufgabe]?.attributes?.Aufgabentyp[0];
        return this.$store.getters.possibleAnswers(pfadaufgabe);
      }
      return this.leftAnswers;
    },
  },


  beforeMount() {
    this.$store.commit('showSecondOverlay', null);
    this.$store.commit('showOverlay', null);
  },

  mounted() {
    this.scrollToTop();
    this.generatePlaceholderAnswerStructure();
  },

  methods: {

    skipPfadaufgabe() {
      this.currentPfadaufgabe += 1;
      this.loadProgress();
    },

    previousPfadaufgabe() {
      this.currentPfadaufgabe -= 1;
      this.loadProgress();
    },

    changePfadaufgabe(index) {
      this.$store.commit('showOverlay', null);
      this.currentPfadaufgabe = index;
      this.loadProgress();
    },

    loadProgress() {
      if (this.currentTraining.progress[this.progressIndex]?.submitted) {
        const progress = this.currentTraining.progress[this.progressIndex];
        this.inputText = progress.inputText;
        this.result = progress.result;
        this.leftAnswers = progress.leftAnswers;
        this.selectedAnswers = progress.selectedAnswers;
        this.selectedListAnswers = progress.selectedListAnswers;
        this.solvedAnswers = progress.solvedAnswers;
        if (this.taskType === 'ComponentAufgabenListenzuordnung') this.loadListAnswers(progress.selectedListAnswers);
      } else {
        this.refresh();
        this.scrollToTop();
      }
    },

    openOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'pfadaufgabenOverview');
    },

    generatePlaceholderAnswerStructure() {
      if (!this.currentTraining.progress[this.progressIndex]?.submitted) {
        this.selectedAnswers = [];
        // eslint-disable-next-line no-underscore-dangle
        if (this.taskType === 'ComponentAufgabenLueckentext') {
          for (let i = 0; i < this.maxAnswers; i += 1) {
            this.selectedAnswers.push('OPTION');
          }
        }
      }
    },

    cancelTraining() {
      this.iosVibrate(1);
      this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
    },

    confirmCancelTraining() {
      this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      this.$store.dispatch('saveTraining', { type: 'Pfadaufgabe' });
      this.$router.push({ name: 'training' });
    },

    checkAnswer() {
      if (this.taskType === 'ComponentAufgabenBegriffspaare') this.checkBegriffspaare();
      if (this.taskType === 'ComponentAufgabenListenzuordnung') this.checkListenzuordnung();
      if (this.taskType === 'ComponentAufgabenVokabel') this.checkVokabel();
      if (this.taskType === 'ComponentAufgabenMcFrage') this.checkMcFrage();
      if (this.taskType === 'ComponentAufgabenLueckentext') this.checkLueckentext();
    },

    checkBegriffspaare() {
      this.validatePairMatch({
        answers: this.selectedAnswers,
        validate: this.pfadaufgaben[this.currentPfadaufgabe].attributes.Aufgabentyp[0].Paar,
      });
    },

    checkListenzuordnung() {
      const answers = [];
      const validate = [];
      this.selectedListAnswers.forEach((listAnswer) => {
        if (listAnswer.list === 1) {
          answers.push(listAnswer.answer);
        }
      });
      answers.sort();
      const validateString = this.pfadaufgaben[this.currentPfadaufgabe].attributes.Aufgabentyp[0].Liste[0].Answers;
      validateString.split(';').sort().forEach((item) => {
        validate.push(item);
      });
      this.validateStringMatch({
        answers: answers.toString().trim(),
        validate: validate.toString().trim(),
      });
    },

    checkVokabel() {
      this.validateVokabelMatch({
        answers: this.selectedAnswers.toString().replace(/,/g, ';').toLowerCase(),
        validate: this.pfadaufgaben[this.currentPfadaufgabe].attributes?.Aufgabentyp[0].answer.toLowerCase(),
      });
    },

    checkMcFrage() {
      let answers = this.selectedAnswers.sort().toString().replace(/,/g, ';');
      const MCanswers = [];
      this.currentMcFrage.Antwort.forEach((antwort) => {
        if (antwort.Richtig) {
          MCanswers.push(antwort.id);
        }
      });
      const validate = MCanswers.sort().toString().replace(/,/g, ';');
      answers = this.selectedAnswers.toString().replace(/,/g, ';');
      this.validateStringMatch(answers.toLowerCase(), validate);
    },

    checkLueckentext() {
      const validate = [];
      const splittedAnswers = this.pfadaufgaben[this.currentPfadaufgabe].attributes?.Aufgabentyp[0].content.split('</s>');
      splittedAnswers.pop();
      splittedAnswers.forEach((answer) => {
        const splitted = answer.split('<s>').pop();
        validate.push(splitted);
      });
      this.validateStringMatch({ answers: this.selectedAnswers.toString(), validate: validate.toString() });
    },

    success() {
      this.result = 'success';
      this.iosVibrate(3);
      this.$store.commit('totalCorrect');
      this.$store.commit('setPfadaufgabeResult', {
        pfadaufgabeId: this.currentPfadaufgabeId,
        submitted: true,
        result: this.result,
        selectedAnswers: this.selectedAnswers,
        selectedListAnswers: this.selectedListAnswers,
        inputText: this.inputText,
        leftAnswers: this.leftAnswers,
        solvedAnswers: this.solvedAnswers,
      });
      this.updateWrongPlayedExercises(this.currentPfadaufgabeId, true);
      this.updateRecentPlayedExercises(this.currentPfadaufgabeId);
      this.savePoints({ pointCategory: 'pfadaufgabenPoints' });
    },

    failure() {
      this.result = 'fail';
      this.iosVibrate(5);
      this.$store.commit('setPfadaufgabeResult', {
        pfadaufgabeId: this.currentPfadaufgabeId,
        submitted: true,
        result: this.result,
        selectedAnswers: this.selectedAnswers,
        selectedListAnswers: this.selectedListAnswers,
        inputText: this.inputText,
        leftAnswers: this.leftAnswers,
        solvedAnswers: this.solvedAnswers,
      });
      this.updateWrongPlayedExercises(this.currentPfadaufgabeId, false);
      this.updateRecentPlayedExercises(this.currentPfadaufgabeId);
    },

    updateWrongPlayedExercises(id, correct) {
      const wrongPlayedArray = this.wrongPlayed;
      const storeID = 'ids';
      if (wrongPlayedArray[storeID].includes(id)) {
        if (correct) {
          const itemIndex = wrongPlayedArray[storeID].indexOf(id);
          wrongPlayedArray[storeID].splice(itemIndex, 1);
        }
      } else {
        wrongPlayedArray[storeID].push(id);
      }
      this.updateUserDataWrongPlayedExercises({ id: this.userId, wrongPlayedExercises: wrongPlayedArray });
    },

    updateRecentPlayedExercises(id) {
      const recentPlayedArray = this.recentPlayed;
      const totalPlayedArray = this.totalPlayed;
      if (!totalPlayedArray.ids.includes(id)) {
        totalPlayedArray.ids.push(id);
      }
      if (!recentPlayedArray.ids.includes(id)) {
        if (recentPlayedArray.ids.length >= process.env.VUE_APP_RECENT_PLAYED_QUANTITY) {
          recentPlayedArray.ids.splice(1, 1);
        }
      } else {
        const index = recentPlayedArray.ids.indexOf(id);
        recentPlayedArray.ids.splice(index, 1);
      }
      recentPlayedArray.ids.push(id);
      this.updateUserDataRecentPlayedExercises({ id: this.userId, recentPlayedExercises: recentPlayedArray, totalPlayedExercises: totalPlayedArray });
    },

    nextPfadaufgabe() {
      if (!this.savingProgress) {
        if (this.currentPfadaufgabe < this.pfadaufgaben.length - 1) {
          this.currentPfadaufgabe += 1;
          this.leftAnswers = [];
          this.loadProgress();
        } else {
          this.$store.commit('finishTraining');
          this.$store.commit('showOverlay', 'results');
        }
      } else {
        console.log('still saving');
      }
    },

    finishTraining() {
      this.$store.commit('finishTraining');
      this.$store.commit('showOverlay', 'results');
    },

  },
};
</script>

<style>
.hyphen {
    -webkit-hyphens: auto; /* for Safari */
    hyphens: auto;
}
</style>
