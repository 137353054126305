<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'pfadaufgabenOverview'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-visby text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full h-1/6 flex-shrink-0" @click="closeOverview"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl flex flex-col">

          <!-- Heading -->
          <div class="text-xl cursor-pointer px-5 pt-5" @click="closeOverview">schließen</div>
          <div class="mt-6 font-visby font-bold text-3xl px-5">Aufgaben Übersicht</div>

          <!--  Shortcuts -->
          <div class="flex flex-col mt-5 font-visby flex-grow overflow-y-auto scrollbar p-5">
            <div v-for="(aufgabe, index) in pfadaufgaben" :key=aufgabe class="flex flex-row items-center mb-5" @click="$emit('changePfadaufgabe', index)">
              <div class="transition duration-150 ease-in-out w-8 h-8 mr-5 text-base bg-white rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center cursor-pointer md:hover:bg-new-black md:hover:text-white"
                :class="{
                  'ring ring-new-black' : currentPfadaufgabeId === aufgabe.id,
                  'bg-red-600 text-white' : getResult(aufgabe.id) === 'fail',
                  'bg-green-500' : getResult(aufgabe.id) === 'success',
                  }">
                {{index + 1}}
              </div>
              <div v-if="aufgabe.attributes?.Aufgabenbeschreibung">{{ aufgabe.attributes.Aufgabenbeschreibung }}</div>
            </div>
            <resultLegend class="mb-28" />
          </div>
          <!--  Shortcuts -->


        </div>
        <!-- Content -->

        <!-- Button -->
        <div v-if="!reviewMode" class="fixed md:static bottom-0 pb-5 min-h-28 w-full p-5 bg-new-yellow md:bg-new-yellow md:shadow-none md:border-t-2 md:border-new-yellow2">
          <customButton label='Training beenden' @click="$emit('buttonClicked')"  />
        </div>
        <!-- Button -->

      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import resultLegend from './resultLegend.vue';
import customButton from '../../../symbols/customButton.vue';

export default {
  name: 'pfadaufgabenOverview',
  props: ['pfadaufgaben', 'currentPfadaufgabeId', 'reviewMode'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { resultLegend, customButton },


  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    currentProgress() {
      return this.$store.state.training?.currentTraining?.progress;
    },
  },

  methods: {

    getResult(pfadaufgabeId) {
      const progressIndex = this.currentProgress.findIndex((pfadaufgabe) => pfadaufgabe.pfadaufgabeId === pfadaufgabeId);
      return this.currentProgress[progressIndex].result;
    },

    closeOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
